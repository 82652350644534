document.addEventListener('DOMContentLoaded', () => {

  // Unix timestamp (in seconds) to count down to
  let twoDaysFromNow = (new Date().getTime() / 1000) + (86400 * 60) + 1;

  // Set up FlipDown
  let flipdown = new FlipDown(twoDaysFromNow)

    // Start the countdown
    .start()

    // Do something when the countdown ends
    .ifEnded(() => {
      console.log('The countdown has ended!');
    });
});


hidefb = () => {
  let x = document.getElementById("fbframe");
  let y=document.getElementById("btnchange");
  if (x.style.display == 'none') {
    x.style.display = 'block';
    y.innerHTML='X';
  } else {
    x.style.display = 'none';
    y.innerHTML='Facebook';
  }
}

   
     